.process-module-actions-container {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  margin-bottom: 37px;
}

.process-module-actions-container .create-new-process-button,
.process-buttons-container .edit-button,
.process-buttons-container .published-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #54bdc1 !important;
  border: none;
  color: #fff;
  margin-right: 10px;
  text-transform: unset;
}
.process-buttons-container .published-button {
  width: 135px !important;
}

.process-buttons-container .publish-button,
.process-buttons-container .revision-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eaedef !important;
  border: none;
  margin-right: 10px;
  color: black !important;
  width: 135px !important;
  text-transform: unset;
}
.model-del-button {
  padding: unset;
  color: #ffff;
  background-color: transparent !important;
  width: unset;
  border-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
}

.order-by-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 142px;
}

.pm-action-btn-tittle {
  margin-right: 10px;
}

.name-of-process-model-list {
  width: auto;
}

.process-buttons-container .edit-button {
  width: auto;
  min-width: 50px;
}

.publish-button {
  width: auto;
  min-width: 50px;
}

.revision-button {
  width: auto;
  min-width: 50px;
}

.process-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: solid 1px #eaedef; */
  /* height: 62px; */
  /* align-items: center; */
  border-radius: 10px;
  /* margin-bottom: 15px;
  margin-top: 15px; */
  padding: 10px 20px;
}
.process-title {
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 150px;
}
.expand-process-viewer {
  height: 550px;
}

.process_error_display {
  position: absolute;
  top: 90%;
  left: 10%;
  background: white;
  width: 75%;
  min-height: 10%;
  border-left: 2px solid red;
  border-right: 2px solid red;
}

.process_error_display_para {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9999;
  width: 100%;
  height: 90%;
  margin-top: -0.2%;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
}

.pm-dropzone-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  max-height: calc(100% - 40px);
}

/* .show-viewer-canvas {
  flex: 1;
  height: 100%;
  width: calc(100% - 370px);
  display: flex;
  margin-top: 10px;
} */
.hide-viewer-canvas {
  width: 0px;
  height: 0px;
  display: flex;
  overflow: hidden;
}

.process-buttons-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.process-diagram-container {
  display: flex;
  min-width: 150px;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.version-btn {
  background-color: #eaedef !important;
  color: black !important;
  border-color: transparent !important;
}

.process-model-with-actions-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.process-model-with-canvas-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100% - 36px);
}

.show-version-of-process-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  width: 370px;
  margin-right: 60px;
}

.hide-version-of-process-container {
  display: none;
}
.list-of-models-container {
  flex: 1;
  overflow: auto;
}

.list-of-models-container::-webkit-scrollbar-thumb,
.versions-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  color: red;
  background-color: #3f3f3f;
  border-radius: 30px;
}

.list-of-models-container::-webkit-scrollbar-track,
.versions-container::-webkit-scrollbar-track {
  background-color: white;
}
.list-of-models-container::-webkit-scrollbar,
.versions-container::-webkit-scrollbar {
  width: 10px;
  background-color: white;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}
.name-of-process-model-list {
  margin-left: 21px;
  font-weight: bold;
  margin-bottom: 15px;
}

.order-by-down-arrow-container {
  display: flex;
  flex: 1;
  justify-content: flex;
}

.asc-orderby-icon {
  transform: rotate(0deg);
}

.desc-orderby-icon {
  transform: rotate(180deg);
}

@media (max-width: 810px) {
  .process-title {
    font-size: 16px;
  }
  
  .model-del-button {
    margin-left: 4px;
  }
  .publish-button,
  .revision-button {
    width: auto !important;
  }

  .pm-action-btn-tittle {
    display: none;
  }

  .process-buttons-container .edit-button,
  .process-buttons-container .publish-button,
  .process-buttons-container .revision-button,
  .order-by-button,
  .process-buttons-container .published-button {
    width: auto !important;
  }
}

.list-of-models-container .djs-palette {
  display: none;
}
