.show-version-process-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.version-of-process-title {
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
  margin-bottom: 10px;
  padding-right: 17px;
}

.version-btn-not-selected,
.version-btn-selected {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 192px;
  width: 100%;
  max-width: 192px;
  height: 41px;
  padding: 0px 22px;
  background: #eaedef;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: auto;
}

.version-btn-selected {
  min-width: 241px;
  height: 41px;
  border: 2px solid #54bdc1;
  border-radius: 10px;
}

.version-btn-selected {
  margin-left: auto;
}

.versions-container {
  height: 100%;
  overflow-y: auto;
}
.process-model-revert-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 41px;
  height: 41px;
  left: 1478px;
  top: 596px;
  background: #dee2e6 !important;
  border-radius: 6px;
  border-color: transparent !important;
  outline: none !important;
}

.version-icon {
  width: 23.69px;
  height: 23.69px;
}

.pm-revert-btn-img {
  width: 23.69px;
  height: 23.69px;
  left: 1487px;
  top: 605px;
}

.revision-model-check-icon {
  margin-right: auto;
}

.version-btn-wrapper {
  display: flex;
}

.show-viewer-canvas .djs-element.selected .djs-outline {
  outline: none !important;
  visibility: collapse !important;
  stroke: transparent !important;
}

.show-viewer-canvas ::selection,
.show-viewer-canvas ::-moz-selection {
  color: #fff !important;
  background: #dda0dd !important;
  text-shadow: 1px 1px 2px #b040b0 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 0.375rem 0.75rem !important;
  line-height: 1.5 !important;
  text-transform: unset !important;
}
