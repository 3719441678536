.djs-shape > g > text > tspan {
  fill: black;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-size: 12px !important;
}
.djs-direct-editing-content {
  background: white;
  border: 1px solid lightgray;
}
.djs-palette-open {
  /* width: calc(100% - 118px) !important; */
  float: right;
  overflow: visible !important;
}
.remove_icon {
  font-weight: 900;
  background: lightgray;
  border-radius: 50px;
}

.new-process-container {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* padding: 30px 0px 0px 30px; */
  position: relative;
}
.new-process-actions-container {
  display: flex;
  align-items: center;
}
.process-model-name-input input {
  width: 100%;
  background-color: transparent;
  border: 2px dashed #54bdc1;
  font-size: 16px;
  padding: 6px 12px;
  border-radius: 0.375rem;
}
.new-process-model-form-control {
  margin-left: 20px !important;
  margin-right: 8px !important;
}
.back-button {
  height: 39px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  background-color: #54bdc1 !important;
  border: none;
  color: #fff !important;
}

.edit-page-actions-container {
  width: 185px;
  height: 39px;
  margin-left: 19px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #eaedef !important;
}

.zoom-actions-container {
  position: absolute;
  top: 195px;
  display: flex;
  left: 29px;
  background-color: #fff;
  z-index: 10;
}

.zoom-actions-container .zoom-in-btn,
.zoom-actions-container .zoom-out-btn {
  cursor: pointer;
}
.organisation-panel-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
}
.organisation-panel-container > .filter {
  height: 35px;
  width: 35px;
}
.organization-panel-specs {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 4px;
  flex-wrap: wrap;
}
.acc-errors-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.decrease-opp-width {
  width: calc(100% - 490px);
}

.show-opp {
  padding: 20px 16px;
  align-items: flex-start;
}

.opp-searchbar-container {
  display: flex;
  max-width: 250px;
}



.canvas {
  display: flex;
  flex: 1;
}

.content {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  flex: 1;
  margin-top: 20px;
}

.bio-properties-panel-container {
  margin-top: 20px;
  height: unset !important;
}

.keyboardicon-container-up {
  display: flex;
  margin-left: 20px;
}

.keyboardicon-container-down {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  padding-top: 14px;
  transform: rotate(180deg);
}

.bjs-container .bjs-powered-by {
  display: none;
}

.property-panel-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #c6c6c6;
}

.select-component-container {
  margin-bottom: 24px;
}

.bio-properties-panel-header .bio-properties-panel-header-icon,
.bio-properties-panel-header .bio-properties-panel-header-actions {
  display: none;
}

.bio-properties-panel-header {
  display: flex;
  justify-content: space-between;
}
.bio-properties-panel-header-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;

  display: flex;
  align-items: center;
}
.bio-properties-panel-header-labels {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  color: #54bdc1;
  display: flex;
  align-items: center;
  text-align: right;
}

.publish-btn-disable,
.save-btn-disable {
  pointer-events: none;
  opacity: 0.3;
}

.publish-btn-enable,
.save-btn-enable {
  pointer-events: unset;
  opacity: 1;
}

.save-icon,
.act-upload-button,
.depublish-button {
  cursor: pointer;
}

.disable-button {
  cursor: not-allowed;
  pointer-events: none;
}

.canvas .djs-palette {
  width: 96px !important;
  z-index: 10;
  left: -100px;
  top: 0px;
}
.new-process-container .djs-minimap .toggle {
  display: none;
}
.new-process-container .djs-minimap {
  top: 0px;
}
.new-process-container .djs-minimap .map {
  height: 100px !important;
  width: 160px !important;
}
.new-process-container .djs-minimap .viewport-dom {
  height: 80px !important;
  width: 100px !important;
}

.resizable-container {
  display: flex !important;
  flex-direction: column;
  height: 0px;
  z-index: 999;
  max-height: 100%;
  /* width: calc(100% - -27px) !important; */
  /* margin-left: -28px; */
  bottom: 0;
  /* min-width: 308px; */
  width: 100% !important;
  /* background-color: #f8f9fa; */
}

.resizable-container-relative {
  position: relative !important;
  border-top: 1px solid lightgray;
}
.resizable-container-absolute {
  position: absolute !important;
}

.organisation-department-btn {
  text-align: left;
  font-size: 12px;
  width: 100%;
  height: 30px;
  color: #000000;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.organisation-department-btn:hover {
  background-color: #e8f7fa;
}

.organisation-participant-btn {
  display: flex;
  align-items: center;
  max-width: auto;
  cursor: pointer;
  text-align: left;
  font-size: 12px;
  width: 180px;
  height: 25px;
  border-radius: 7px;
  display: flex;
  margin-bottom: 7px !important;
  padding: 12px 8px;
  justify-content: flex-start !important;
}
.organisation-participant-btn-txt {
  font-weight: 400;
  font-size: 15px;
  margin: 4px 2px;
}

.org-participant-title {
  font-size: 19px;
  margin-bottom: 5px;
  margin-left: 0.5rem;
}

.empty-space-container-resizable-func {
  display: flex;
  flex: 1;
  height: 100%;
}

.overlapped-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2%;
  font-weight: 500;
  font-size: 17px;
}

.error-tooltip {
  background-color: #ff9999;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 5px;
  min-width: 100px;
  cursor: pointer;
  opacity: 0.9;
}

.accordion-button {
  background-color: rgb(244, 245, 247);
  padding: 1rem 0.7rem;
  height: 50px;
}

.accordion-button:focus {
  border-color: none;
  box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
}

.accordion-body {
  padding: 0;
}

.accordion-item {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0);
  border-bottom-left-radius: calc(0);
}

.accordion-button:not(.collapsed) {
  visibility: visible;
  color: black;
  font-weight: 800;
  background-color: rgb(244, 245, 247);
  box-shadow: inset 0 0 0 rgb(0 0 0 / 13%);
}

.accordion-button.collapsed > div > .componentBtn,
.accordion-button.collapsed > div > .descriptionBtn {
  visibility: hidden;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../../../../image/arrow-down-icon.png");
  transform: rotate(-180deg);
}
.accordion-collapse .collapse .show {
  background-color: #54bdc1;
}

/** Tooltop Style */

.tooltip {
  position: relative;
  display: none;
  z-index: 10000;
  opacity: 1;
}

.tooltip .tooltip-content {
  position: absolute;
  top: 28px;
  left: -100px;
  margin-left: -60px;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 150px;
  max-width: 200px;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.8);
  border: 1px solid lightgray;
}
.tooltip-list {
  font-size: 12px !important;
  font-weight: 600;
}

.tooltip .tooltip-subheader {
  font-weight: bold;
  font-style: italic;
}

.tooltip .tooltip-line {
  padding-left: 0.75em;
  width: 100%;
  display: flex;
  align-items: baseline;
}

.tooltip-line .tooltip-key {
  color: lightgrey;
  display: inline-block;
  white-space: nowrap;
}

.tooltip-line .tooltip-value {
  display: inline-block;
  overflow: hidden;
  word-wrap: break-word;
}

.tooltip-line .tooltip-value-text {
  font-family: inherit;
}

.tooltip-line .tooltip-value-code {
  font-family: monospace, monospace;
  font-size: 1em;
}

.tooltip .tooltip-no-properties {
  text-align: center;
}

/* .resizable-container .accordion {
  height: 100%;
}

.resizable-container .accordion .accordion-body {
  height: 100%;
}

.resizable-container .accordion .accordion-body .organization-panel-specs {
  height: 100%;
} */

.loadmore-act-btn {
  display: flex;
  background-color: #54bdc1 !important;
  outline: none !important;
  width: 101px;
  border-color: transparent !important;
  justify-content: center;
  padding: 3px;
  font-size: 12px;
  margin-left: 10px;
  margin-top: auto;
}

/*! Tooltip Style End  */

.go-back-title-container {
  width: fit-content;
  min-width: max-content;
}
.inner-opp-acc-wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
}

.acc-column-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.users-btns-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}

.errors-badge {
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: rgb(220, 53, 69);
}

.selected-element-details-container {
  display: flex;

  justify-content: space-between;
  width: 100%;
  margin-bottom: -18px;
  margin-top: -16px;
}

.selected-element-name {
  display: flex;
  font-style: normal;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #54bdc1;
  margin-left: auto;
  text-transform: capitalize;
}

.properties-panel-resizable-container {
  min-width: 75px;
  width: 449px;
  max-width: 800px;
  /* margin-top: -50px; */
  max-height: calc(100% + 50px);
  /* margin-top: -66px; */
}

@media (min-width: 1400px) {
  .canvas .djs-palette {
    width: 100px !important;
  }
}

@media (max-width: 768px) {
  .opp-searchbar-container {
    display: none;
  }
  .filter {
    display: none;
  }
  .back-button-title {
    display: none;
  }
}

/* Define the CSS animation for zooming effect */
@keyframes animate-zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

/* Apply the CSS animation to the canvas during zooming */
.animate-zoom {
  animation-name: animate-zoom;
  animation-duration: 500ms; /* Duration of the animation */
  animation-timing-function: ease-out; /* Animation timing function */
}

.djs-palette-entries > div[data-group="create"] {
  display: none;
}