.treeItem {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.treeItem .MuiTreeItem-content{
padding-top: 10px;
padding-bottom: 10px;
border-radius: 8px;
}

